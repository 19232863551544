export const getStatusText = (status) => {
  let text = "Order updated.";

  switch (status) {
    case 0:
      text = "Order placed successfully.";
      break;
    case 1:
      text = "Order accepted by Admin / Store.";
      break;
    case 2:
      text = "Order assigned to Executive.";
      break;
    case 3:
      text = "Order picked-up.";
      break;
    case 4:
      text = "Order delivered successfully.";
      break;
    case 5:
      text = "Order delivered successfully.";
      break;
    case 6:
      text = "Order cancelled.";
      break;
    case 7:
      text = "Order Rejected";
      break;
    default:
      text = "Order updated.";
  }

  return text;
};
