import { useRef, useState } from "react";
import { FaEdit, FaEye, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userImageUplaodRequest } from "../../../network/service/ImageUploadService";
import { displayLargeImage } from "../../../redux/slice/formUpdateSlice";
import noImage from "../../../assets/no_image.jpg";
function AdminUserItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);

  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: item.id,
      fileData: form_data,
    };
    await dispatch(userImageUplaodRequest(requestBody)).then((response) => {});
  };
  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { item } })
    );
  };
  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };
  const editItem = () => {
    navigate("/acu", { state: { item: item } });
  };
  return (
    <>
      <tr>
        <td> {item.id} </td>
        <td className="align-middle">
          <div className="cat_mainwrap">
            {item.image_path === "" ? null : (
              <div className="cat_btnwrap">
                <FaRegEdit
                  className="faWhite"
                  onClick={() => onNgImageClick()}
                />

                <FaEye className="faWhite" onClick={() => showLargeImage()} />
              </div>
            )}
            <input
              type="file"
              onChange={handleImageInputChange}
              ref={hiddenFileInput}
              style={{ display: "none" }} // Make the file input element invisible
            />
            {file ? (
              <>
                <img
                  src={file}
                  alt={item.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              </>
            ) : item.image_path !== "" ? (
              <img
                src={item.image_path}
                alt={item.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
              />
            ) : (
              <img
                src={noImage}
                alt={item.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
                onClick={() => onNgImageClick()}
              />
            )}
          </div>
        </td>
        <td>
          <Link to="/customerOrders">{item.name}</Link>
        </td>
        <td> {item.phone} </td>
        <td> {item.email} </td>

        <td className="align-middle">
          <p className="col-lg-12 d-flex justify-content-center">
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>
            {/* <span className="delete-icon-btn">
            <FaTrash />
          </span> */}
          </p>
        </td>
      </tr>
    </>
  );
}

export default AdminUserItem;
