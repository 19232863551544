import { FaEdit, FaEye, FaRegEdit, FaTrash } from "react-icons/fa";
import {
  displayRemoveAlert,
  updateFormInfo,
} from "../../../redux/slice/formUpdateSlice";
import { useDispatch } from "react-redux";
import noImage from "../../../assets/no_image.jpg";
import { useRef, useState } from "react";
import {
  couponImageUplaodRequest,
  productNxImageUplaodRequest,
} from "../../../network/service/ImageUploadService";

function LocationCouponsItem(props) {
  const dispatch = useDispatch();
  const [product] = useState(props.item);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState();

  const onImageClick = () => {
    dispatch(updateFormInfo({ imageUpload: true, formData: { product } }));
  };

  const limitApply = (val) => {
    switch (val) {
      case "YES":
        return "Scheduled";
      case "NO":
        return "Not Scheduled";
    }
  };

  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.id,
      fileData: form_data,
    };
    await dispatch(couponImageUplaodRequest(requestBody)).then((response) => {
      console.log("response...", response);
    });
  };

  const deleteItem = () => {
    // console.log(item);
    dispatch(
      displayRemoveAlert({
        displayRemove: true,
        formData: { item: product },
        type: "coupon",
      })
    );
  };
  return (
    <>
      <tr>
        <td>{props.sno}</td>
        <td>{product.created_at.substring(0, 10)}</td>
        <td>{product.name}</td>
        <td className="align-middle">
          <div className="cat_mainwrap" onClick={() => onImageClick()}>
            {product.image_path === null ? null : (
              <div className="cat_mainwrap" onClick={() => onNgImageClick()}>
                {product.image_path === "" ? null : (
                  <div className="cat_btnwrap">
                    <a href="">
                      <FaRegEdit />
                    </a>
                    <a href="">
                      <FaEye />
                    </a>
                  </div>
                )}
                <input
                  type="file"
                  onChange={handleImageInputChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
                {file ? (
                  <>
                    <img
                      src={file}
                      alt={product.name}
                      className="img-thumbnail"
                      width="50px"
                      height="50px"
                    />
                  </>
                ) : product.image_path !== "" ? (
                  <img
                    src={product.image_path}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                ) : (
                  <img
                    src={noImage}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                )}
              </div>
            )}
          </div>
        </td>

        <td>
          {product.start_date} to
          <br /> {product.end_date}
        </td>
        <td>{limitApply(product.limit_apply)}</td>
        <td>{product.used_count}</td>
        <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="edit-icon-btn">
              <FaEdit />
            </span>
            <span className="delete-icon-btn" onClick={deleteItem}>
              <FaTrash />
            </span>
          </div>
        </td>
      </tr>
    </>
  );
}

export default LocationCouponsItem;
