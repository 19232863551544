import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { getInfoRequest } from "../../network/service/DashboardService";
import RecentOrders from "../order/RecentOreders";
import { todayDate } from "../../utils/dateUtil";
import Clock from "../../utils/clock";
import { Tab, Tabs } from "react-bootstrap";
import PendingOrders from "../order/PendingOreders";
import InprogressOrders from "../order/InprogressOreders";
import DeliveredOrders from "../order/DeliveredOreders";
import CancelledOrders from "../order/CancelledOreders";
import Attendance from "../attendance/attendance";
const { RangePicker } = DatePicker;

function Dashboard() {
  const dispatch = useDispatch();
  const [dashboardInfo, setDashBoardInfo] = useState({});

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    await dispatch(getInfoRequest()).then((reponse) => {
      if (reponse.data != null && reponse.data.result) {
        setDashBoardInfo(reponse.data.result);
      }
    });
  };

  return (
    <main className="container-fluid dashboard" style={{ marginTop: "20px" }}>
      <div className="row">
        {/* <div className="col-md-12">
          <h3 className="dashboard-title">DASHBOARD</h3>
        </div> */}

        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-4">
                  <div class="card overflow-hidden">
                    <div class="bg-soft" style={{ background: "#028b92" }}>
                      <div class="row">
                        <div class="col-7">
                          <div class="text-primary p-3">
                            <h6 class="text-white">Welcome Back!</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body pt-0" style={{ marginTop: "10px" }}>
                      <div class="row">
                        <div class="col-sm-7">
                          <h5 class="font-size-15 text-truncate">
                            {localStorage.getItem("userName")}
                          </h5>
                          <p class="text-muted mb-0 text-truncate">
                            {localStorage.getItem("userPhone")}
                          </p>
                        </div>

                        <div class="col-sm-5">
                          <div class="pt-4">
                            <div class="mt-4">
                              <Clock />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="card">
                    <div class="card-body">
                      <h4 class="card-title mb-4">Personal Information</h4>

                      <p class="text-muted mb-4">Hi I'm UserName,</p>
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>Cynthia Price</td>
                            </tr>
                            <tr>
                              <th scope="row">Organisation :</th>
                              <td>Organisation Name</td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>cynthia@gmail.com</td>
                            </tr>
                            <tr>
                              <th scope="row">Status :</th>
                              <td>
                                <span class="badge badge-pill badge-soft-success font-size-11">
                                  Active
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="col-xl-8">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="card mini-stats-wid">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">Branches</p>
                              <h4 class="mb-0">
                                {dashboardInfo.totalStoresCount}
                              </h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                              <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                <span class="avatar-title">
                                  <i class="bx bx-list-ul font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card mini-stats-wid">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">Products</p>
                              <h4 class="mb-0">
                                {" "}
                                {dashboardInfo.totalProducts}
                              </h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center ">
                              <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-primary">
                                  <i class="bx bx-upload font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card mini-stats-wid">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">Employees</p>
                              <h4 class="mb-0">
                                {dashboardInfo.totalEmployees}
                              </h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                              <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-primary">
                                  <i class="bx bx-copy-alt font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style={{ marginTop: "10px" }}>
                    <div class="col-md-4">
                      <div class="card mini-stats-wid">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">Customers</p>
                              <h4 class="mb-0">
                                {dashboardInfo.totalCustomers}
                              </h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                              <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                <span class="avatar-title">
                                  <i class="bx bx-list-ul font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card mini-stats-wid">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">Total Orders</p>
                              <h4 class="mb-0">{dashboardInfo.totalOrders}</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center ">
                              <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-primary">
                                  <i class="bx bx-upload font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card mini-stats-wid">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">Today Orders</p>
                              <h4 class="mb-0">{dashboardInfo.todayOrders}</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                              <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-primary">
                                  <i class="bx bx-copy-alt font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" style={{ marginTop: "10px" }}>
                <div class="card-body">
                  <h4 class="card-title mb-4">Orders</h4>
                  <Tabs
                    defaultActiveKey="today"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="today" title="Today">
                      <RecentOrders />
                    </Tab>

                    <Tab eventKey="pending" title="Pending">
                      <PendingOrders />
                    </Tab>

                    <Tab eventKey="inprogress" title="In-Progress">
                      <InprogressOrders />
                    </Tab>

                    <Tab eventKey="delivered" title="Delivered">
                      <DeliveredOrders />
                    </Tab>

                    <Tab eventKey="cancelled" title="Cancelled">
                      <CancelledOrders />
                    </Tab>
                  </Tabs>

                  {/* <h4 class="card-title mb-4">Today Orders</h4>
                  <div class="table-responsive">
                    <RecentOrders />
                  </div> */}
                </div>
              </div>

              <div class="card" style={{ marginTop: "10px" }}>
                <div class="card-body">
                  <h4 class="card-title mb-4">Today Logins</h4>

                  <div class="table-responsive">
                    <Attendance />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
