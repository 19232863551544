import { useDispatch, useSelector } from "react-redux";
import { priceListAction } from "../../../network/store/action/ProductResponseAction";
import { useEffect, useState } from "react";
import {
  displayForm,
  formUpdateStatus,
} from "../../../redux/slice/formUpdateSlice";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { priceCreateUpdateAction } from "../../../network/store/action/ProductPriceAction";
import { updateMessage } from "../../../redux/slice/toastSlice";

function AdminItemPriceItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [item, setItem] = useState(props.item);
  const [itemStatus, setItemStatus] = useState(item.product_availability);
  const [stockStatus, setStockStatus] = useState(item.stock_availability);

  const editItem = () => {
    // dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
    navigate("/apu", { state: { item } });
  };
  const onItemStatusChange = async () => {
    let status = "YES";
    if (item.product_availability == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      product_availability: status,
      id: item.id,
    };

    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.product_availability = status));
        setItemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const onStockStatusChange = async () => {
    let status = "YES";
    if (item.stock_availability == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      stock_availability: status,
      id: item.id,
    };

    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.stock_availability = status));
        setStockStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <>
      <tr>
        <td>{item.sku}</td>
        <td>
          {item.qty} {"-"}
          {item.measure}
        </td>
        <td>₹{item.purchase_price}</td>
        <td>₹{item.price}</td>
        <td>
          {item.offer}% - ₹{item.offer_price}
        </td>
        {/* <td>₹{item.b2bprice}</td> */}
        {/* <td>
          {item.b2boffer}% - ₹{item.b2boffer_price}
        </td> */}
        <td> {item.stock_quantity}</td>

        <td className="align-middle">
          {/* <p>{item.status}</p> */}
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              onChange={onItemStatusChange}
              checked={itemStatus === "YES" ? true : false}
            />
          </div>
        </td>

        <td className="align-middle">
          {/* <p>{item.status}</p> */}
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              onChange={onStockStatusChange}
              checked={stockStatus === "YES" ? true : false}
            />
          </div>
        </td>

        <td className="align-middle">
          <p className="col-lg-12 d-flex justify-content-center">
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>

            {/* <span className="delete-icon-btn">
              <FaTrash />
            </span> */}
          </p>
        </td>
      </tr>
    </>
  );
}

export default AdminItemPriceItem;
