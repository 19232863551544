import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import AttendItem from "./attendance-item";

import { listAction as storeListAction } from "../../network/store/action/BranchResponseAction";
import { useDispatch, useSelector } from "react-redux";
import { formUpdateStatus } from "../../redux/slice/formUpdateSlice";
import {
  attendanceListAction,
  listAction,
} from "../../network/store/action/UserResponseAction";
import { todayDate } from "../../utils/dateUtil";

function Attendance() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    storeId: "",
    dated: todayDate(),
  });

  useEffect(() => {
    getStores();
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [filterOptions]);

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    if (e.target.name === "orgId") {
      branches.findIndex((obj) => obj.id === e.target.value);
      setfilterOptions({
        ...filterOptions,
        orgId: e.target.value,
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    await dispatch(
      attendanceListAction(
        {
          dated: filterOptions.dated,
          storeId: filterOptions.storeId,
        },
        dispatch
      )
    ).then((reponse) => {
      setFilterList([]);

      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    console.log("search Value", e.target.value);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  return (
    <div className="row m-3">
      <div className="col-md-3">
        <div className="form-group">
          <label>Branch</label>
          <select
            name="orgId"
            className="form-select"
            onChange={(e) => handleChanges(e)}
            value={filterOptions.sectionId}
          >
            <option value={""}>{"All Branches"}</option>
            {branches &&
              branches.length > 0 &&
              branches.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      <div className="col-md-12 card-1 table-responsive">
        <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Sno</th>
              <th scope="col">Employee Name</th>
              <th scope="col">Branch</th>
              <th scope="col">Login Time</th>
              <th scope="col">Logout Time</th>
              <th scope="col">Login Location</th>
            </tr>
          </thead>

          {searchVal === "" && resultList && resultList.length > 0 ? (
            <tbody>
              {resultList.map((item, i) => {
                return <AttendItem item={item} key={item.id} />;
              })}
            </tbody>
          ) : filterList.length > 0 ? (
            <tbody>
              {filterList.map((item, i) => {
                return <AttendItem item={item} key={item.id} />;
              })}
            </tbody>
          ) : (
            <p>No Data found</p>
          )}
        </table>
      </div>
    </div>
  );
}

export default Attendance;
