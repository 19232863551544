import React, { useState } from "react";
import { Form, Button, ProgressBar } from "react-bootstrap";
import Stepper from "react-stepper-horizontal";

import { useLocation, useNavigate } from "react-router-dom";
import SelecatableStores from "../Store/SelectableStores";
import ProductPrice from "../ProductTwo/productPrices";
import PriceCreateUpdate from "./price-create-update";

const ProductPriceCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [priceId, setPriceId] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className="container-fluid">
      <div>
        <Stepper
          steps={[{ title: "Price" }, { title: "Assign Branches" }]}
          activeStep={activeStep}
          activeColor={"#5096FF"}
          titleFontSize={18}
          size={38}
          defaultColor="#eee"
          activeTitleColor="#000"
          completeTitleColor="#5096FF"
          defaultTitleColor="#000"
          circleFontColor="#000"
          completeBarColor="#5096FF"
          lineMarginOffset={0}
        />
      </div>

      {activeStep === 0 && (
        <PriceCreateUpdate
          id={location.state.id}
          onSubmitClick={(id) => {
            setPriceId(id);
            handleNext();
          }}
        />
      )}
      {activeStep === 1 && (
        <SelecatableStores
          type={"price"}
          id={location.state.id}
          priceId={priceId}
          onSubmitClick={() => {
            navigate(-1);
          }}
        />
      )}
    </div>
  );
};

export default ProductPriceCreate;
