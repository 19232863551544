import { useState, memo, useRef, useEffect } from "react";
import {
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../../network/service/ImageUploadService";
import StoreProductListItem from "./store-product-list-item";
import { priceCreateUpdateAction } from "../../../network/store/action/ProductPriceAction";

const StorePPriceListItem = (props) => {
  const [price, setItem] = useState(props.itemPrice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemStatus, setItemStatus] = useState(price.product_availability);

  useEffect(() => {
    console.log("price.product_availability", price.product_availability);
  }, []);

  const onItemStatusChange = async () => {
    let status = "YES";
    if (price.product_availability == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      product_availability: status,
      id: price.id,
      storeId: props.storeId,
    };

    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(price, (price.product_availability = status));
        setItemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <>
      <td>
        {price.qty} {price.measure}
      </td>
      <td>
        {price.b2bprice} {price.price}
      </td>
      <td>
        {price.price} {price.offer}
      </td>

      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onItemStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>

      <td>Edit</td>
    </>
  );
};

export default memo(StorePPriceListItem);
