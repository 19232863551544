import "./App.css";
import Routers from "./Routers";
import { BrowserRouter, HashRouter } from "react-router-dom";
import Sidebar from "./pages/Sidebar/sidebar";
import AppToast from "./components/appToast";
import useNetworkStatus from "./network/netcheckhook";
import { Suspense, useState } from "react";

function App() {
  const { isOnline } = useNetworkStatus();
  const [isActiveNetwork, setActiveNetwork] = useState(false);

  const checkActiveNetwork = async () => {
    fetch("https://www.google.com/", {
      mode: "no-cors",
    })
      .then(() => {
        console.log("CONNECTED TO INTERNET");
        return true;
      })
      .catch(() => {
        console.log("INTERNET CONNECTIVITY ISSUE");
        return false;
      });
  };

  return (
    <BrowserRouter>
      <AppToast />

      {isOnline ? (
        checkActiveNetwork() ? (
          <Suspense>
            <Sidebar>
              <Routers />
            </Sidebar>
          </Suspense>
        ) : (
          <>
            <label style={{ fontSize: "32px" }}>Network Not Available</label>
          </>
        )
      ) : (
        <>
          <label style={{ fontSize: "32px" }}>Network Not Available</label>
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
