import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction } from "../../network/store/action/BranchResponseAction";
import {
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";
import SelectableStoreItem from "./selectable-store-item";
import { selectedItemsStatus } from "../../redux/slice/selectedItemsSlice";
import {
  updateAllStorePriceAction,
  updateAllStoreSinglePriceAction,
} from "../../network/store/action/ProductResponseAction";
import { updateStoreBannersAction } from "../../network/store/action/BannerResponseAction";

function SelecatableStores(props) {
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  // list of items
  const [resultList, setResultList] = useState([]);
  const selectedIds = useSelector(selectedItemsStatus);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };

  const onUpdateClick = async () => {
    if (selectedIds && selectedIds.length > 0) {
      if (props.type === "product") {
        let itemObj = {
          productId: props.id,
          storeIds: selectedIds.toString(),
        };
        await dispatch(updateAllStorePriceAction(itemObj, dispatch)).then(
          (response) => {
            console.log("createUpdateAction", response.result);
            if (response.result) {
              dispatch(
                updateMessage({
                  display: true,
                  message: "Product Created.",
                })
              );
              if (props.onSubmitClick) props.onSubmitClick(response.result.id);
            }
          }
        );
      }
      if (props.type === "price") {
        let itemObj = {
          productId: props.id,
          priceId: props.priceId,
          storeIds: selectedIds.toString(),
        };
        await dispatch(updateAllStoreSinglePriceAction(itemObj, dispatch)).then(
          (response) => {
            console.log("createUpdateAction", response.result);
            if (response.result) {
              dispatch(
                updateMessage({
                  display: true,
                  message: "Product Created.",
                })
              );
              if (props.onSubmitClick) props.onSubmitClick(response.result.id);
            }
          }
        );
      } else if (props.type === "banner") {
        let itemObj = {
          bannerId: props.id,
          storeIds: selectedIds.toString(),
        };
        await dispatch(updateStoreBannersAction(itemObj, dispatch)).then(
          (response) => {
            console.log("createUpdateAction", response.result);
            if (response.result) {
              dispatch(
                updateMessage({
                  display: true,
                  message: "Banners Updated.",
                })
              );
              if (props.onSubmitClick) props.onSubmitClick(response.result.id);
            }
          }
        );
      }
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Select Stores",
        })
      );
    }
  };

  return (
    <>
      <div className="d-flex align-item-center justify-content-center">
        <div className="col-md-8 ">
          <div className="container  card-1">
            <div className="row mt-5 mb-5">
              <div
                className="row justify-content-around tble"
                style={{ padding: "20px" }}
              >
                <div className="col-6">
                  <h3 className="dashboard-title">Select Branches</h3>
                </div>
                <div className="mr-auto col-6 text-end">
                  <button
                    type="button"
                    className="mr-auto btn btn-purple-bg"
                    onClick={() => {
                      onUpdateClick();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>

              {resultList && resultList.length > 0 ? (
                <div className="col-md-12" style={{ padding: "20px" }}>
                  {/* <div className="row justify-content-around tble">
                    <div className="col-6">
                      <h3 className="dashboard-title">Select Branches</h3>
                    </div>
                    <div className="mr-auto col-6 text-end">
                      <button
                        type="button"
                        className="mr-auto btn btn-purple-bg"
                        onClick={() => {
                          onUpdateClick();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div> */}
                  {resultList.map((item, i) => {
                    return <SelectableStoreItem item={item} key={item.id} />;
                  })}
                </div>
              ) : (
                <p>No Data found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelecatableStores;
