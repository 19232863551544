import { COUPONS_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const updateCouponStatusRequest = (data) => async () => {
  try {
    let result = await postRequest(COUPONS_API_PATH.UPDATE_STATUS, data, false);
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
