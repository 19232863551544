import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function B2BCustomerItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const editItem = () => {
    navigate("/b2ba", { state: { item: item } });
  };
  return (
    <>
      <tr>
        <td> {item.id} </td>
        <td className="align-middle">{item.storeName}</td>
        <td className="align-middle">{item.business_name}</td>

        <td>
          <Link to="/customerOrders">{item.name}</Link>
        </td>
        <td> {item.phone} </td>
        <td> {item.email} </td>
        <td className="align-middle">
          <p className="col-lg-12 d-flex justify-content-center">
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>
            {/* <span className="delete-icon-btn">
            <FaTrash />
          </span> */}
          </p>
        </td>
      </tr>
    </>
  );
}

export default B2BCustomerItem;
