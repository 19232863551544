import moment from "moment-timezone";

export const todayBetweenDates = (startDate, endDate) => {
  const today = moment(moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");

  console.log(today);

  if (today.isBetween(startDate, endDate, "days", true)) {
    return "LIVE";
  } else if (today.isSameOrBefore(startDate)) {
    return "Scheduled";
  } else if (today.isAfter(endDate)) {
    return "Completed";
  } else {
    return "Scheduled";
  }
};

export const isAfterDate = (startDate, endDate) => {
  const eDate = moment(
    moment(new Date(endDate)).format("YYYY-MM-DD"),
    "YYYY-MM-DD"
  );

  if (eDate.isSameOrAfter(startDate, "days", true)) {
    return true;
  } else {
    return false;
  }
};

export const todayDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const todayDateDisplay = () => {
  return moment(new Date()).format("DD MMM, YYYY");
};

export const orderFormatedDate = (oDate) => {
  const a = moment(oDate, "YYYY-MM-DD HH:mm.ss").format("MMM DD, YYYY hh:mm A");
  return a;
};
