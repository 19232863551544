import {
  listRequest,
  createUpdateRequest,
  priceListRequest,
  updateAllStorePriceRequest,
  storeListRequest,
  updateAllStoreSinglePriceRequest,
  storePriceListRequest,
  updateSingleStorePriceRequest,
  imageListRequest,
  updateTags,
  tagListRequest,
} from "../../service/ProductService";

/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const listAction = (data) => async (dispatch) => {
  const response = await dispatch(listRequest(data));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const storeListAction = (data) => async (dispatch) => {
  const response = await dispatch(storeListRequest(data));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const priceListAction = (data) => async (dispatch) => {
  const response = await dispatch(priceListRequest(data));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const storePriceListAction = (data) => async (dispatch) => {
  const response = await dispatch(storePriceListRequest(data));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const createUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(createUpdateRequest(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const updateAllStorePriceAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(updateAllStorePriceRequest(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const updateSingleStorePriceAction =
  (requestBody) => async (dispatch) => {
    const response = await dispatch(updateSingleStorePriceRequest(requestBody));
    console.log(response);
    if (
      (response &&
        Object.keys(response).length &&
        response?.data?.status == 201) ||
      200
    ) {
      console.log(response);
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  };

export const updateAllStoreSinglePriceAction =
  (requestBody) => async (dispatch) => {
    const response = await dispatch(
      updateAllStoreSinglePriceRequest(requestBody)
    );
    console.log(response);
    if (
      (response &&
        Object.keys(response).length &&
        response?.data?.status == 201) ||
      200
    ) {
      console.log(response);
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(null);
    }
  };

export const imageListAction = (data) => async (dispatch) => {
  const response = await dispatch(imageListRequest(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const tagUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(updateTags(requestBody));
  console.log("", response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log("", response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const taglistAction = (data) => async (dispatch) => {
  const response = await dispatch(tagListRequest(data));
  console.log("", response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log("", response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
