import { useState, memo, useRef } from "react";
import {
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTag,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  displayLargeImage,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../network/service/ImageUploadService";

const ProductListItemTwo = (props) => {
  const [product] = useState(props.product);
  const [itemStatus, setitemStatus] = useState(product.item.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState();

  const editItem = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/udprdct");
  };

  const editTags = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/ptags");
  };

  const updatePriceItem = (item) => {
    dispatch(updateFormInfo({ displayForm: false, formData: { item } }));
    navigate("/udprice");
  };

  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { product } })
    );
  };

  const onImageClick = () => {
    if (isDsplayAddProduct())
      dispatch(updateFormInfo({ imageUpload: true, formData: { product } }));
  };

  const addPriceItem = () => {
    navigate("/pinfo");
  };

  const navigateDetails = () => {
    navigate("/pinfo");
  };

  const getStockLable = (stock) => {
    if (stock < 1) {
      return <span className="badge bg-danger">{stock}</span>;
    } else if (stock < 10) {
      return <span className="badge bg-warning">{stock}</span>;
    } else if (stock >= 10) {
      return <span className="badge bg-success">{stock}</span>;
    }
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (product.item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        product.item.status = status;
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const navigateProductInfo = () => {
    navigate("/pinfo", { state: { product } });
  };

  const onPromoImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.item.id,
      fileData: form_data,
    };
    await dispatch(productPromoImageUplaodRequest(requestBody)).then(
      (response) => {}
    );
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{product.item.id}</p>
      </td>

      <td className="align-middle">
        <div className="cat_mainwrap" onClick={() => onImageClick()}>
          {/* {product.item.productImage === null ? null : (
            <div className="cat_btnwrap">
              <a href="">
                <FaRegEdit />
              </a>
              <a href="">
                <FaEye />
              </a>
            </div>
          )} */}

          {product.item.productImage !== null &&
          product.item.productImage !== "" ? (
            <img
              src={product.item.productImage}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>

      <td className="align-middle">
        <div className="cat_mainwrap">
          {product.item.preview_image_path === "" ? null : (
            <div className="cat_btnwrap">
              <FaRegEdit
                className="faWhite"
                onClick={() => onPromoImageClick()}
              />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
          )}
          <input
            type="file"
            onChange={handleImageInputChange}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
          {file ? (
            <>
              <img
                src={file}
                alt={product.item.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
              />
            </>
          ) : product.item.preview_image_path !== "" ? (
            <img
              src={product.item.preview_image_path}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
              onClick={() => onPromoImageClick()}
            />
          )}
        </div>
      </td>
      {/* to="/pinfo" state={{ product }} */}
      <td className="align-middle" onClick={navigateProductInfo}>
        <Link>
          <p>{product.item.name}</p>
        </Link>
      </td>
      <td className="align-middle">
        <p>{product.item.hsn}</p>
      </td>

      {/* <td className="align-middle">
        <div class="col-lg-12 d-flex justify-content-center">
          {product.prices && product.prices.length > 0 ? (
            product.prices.length == 1 ? (
              <>
                <div className="price-box">
                  <span
                    className="float-edit-btn"
                    onClick={() => {
                      updatePriceItem(product.prices[0]);
                    }}
                  >
                    <FaEdit />
                  </span>
                  <span>
                    {product.prices[0].qty} {product.prices[0].measure}{" "}
                    {getStockLable(product.prices[0].stock_quantity)}
                  </span>
                  <br />
                  <span>
                    {"SP: "}
                    {product.prices[0].offer_price !== "" ? (
                      <>
                        <b>{"₹" + product.prices[0].offer_price}</b>{" "}
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "grey",
                          }}
                        >
                          {"₹" + product.prices[0].price}
                        </span>
                      </>
                    ) : (
                      <b>{"₹" + product.prices[0].price}</b>
                    )}{" "}
                  </span>
                  <br />
                  <span>{"PP: ₹" + product.prices[0].purchase_price}</span>
                  <br />
                </div>
              </>
            ) : (
              <>
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#02a612",
                    color: "#ffff",
                    borderRadius: "20px",
                    fontSize: ".7rem",
                    maxWidth: "100px",
                  }}
                >
                  {product.prices.length} Variants
                </span>
              </>
            )
          ) : (
            <>
              <span> No Variants </span>
            </>
          )}
        </div>
      </td> */}

      <td className="align-middle" onClick={navigateProductInfo}>
        <div class="col-lg-12 d-flex justify-content-center">
          {product.prices && product.prices.length > 0 ? (
            <>
              {/* <span
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#02a612",
                  color: "#ffff",
                  borderRadius: "20px",
                  fontSize: ".7rem",
                  maxWidth: "100px",
                }}
              > */}
              {product.prices.length} Variants
              {/* </span> */}
            </>
          ) : (
            <>
              <span> No Variants </span>
            </>
          )}
        </div>
      </td>
      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch  col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      {isDsplayAddProduct() && (
        <td className="align-middle">
          <p className="col-lg-12 d-flex justify-content-center">
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>

            <span
              className="edit-rupee-btn"
              onClick={() => {
                navigateProductInfo();
              }}
            >
              <FaIndianRupeeSign />
            </span>

            <span
              className="delete-icon-btn"
              onClick={() => {
                editTags();
              }}
            >
              <FaTag />
            </span>

            {/* <span className="delete-icon-btn">
              <FaTrash />
            </span> */}

            {/* <div className="add-btn-box position-rproductative">
            <span
              className="price-add-btn"
              onClick={() => {
                addPriceItem();
              }}
            >
              <FaPlus />
            </span>
          </div> */}
          </p>
        </td>
      )}
    </tr>
  );
};

export default ProductListItemTwo;
