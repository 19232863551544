import { useState } from "react";
import { todayDateDisplay } from "./dateUtil";

const Clock = () => {
  let time = new Date().toLocaleTimeString();

  const [ctime, setTime] = useState(time);
  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setTime(time);
  };
  setInterval(UpdateTime);
  return (
    <h6>
      {ctime} <br></br> {todayDateDisplay()}
    </h6>
  );
};
export default Clock;
