import { DASHBOARD_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";

export const getInfoRequest = () => async () => {
  let result = await getRequest(
    DASHBOARD_API_PATH.DASHBOARD_DATA_PATH,
    null,
    false
  );

  return Promise.resolve(result);
};
