import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

function StoreAdminItem(props) {
  return (
    <>
      <tr>
        <td> {props.id} </td>
        <td>
          <Link to="/userProfile">{props.name}</Link>
        </td>
        <td> {props.phone} </td>
        <td> {props.orgName} </td>
        
        <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"s
                    role="switch"
                    checked={props.status === "ACTIVE" ? true : false}

                />
            </div>
        </td>
        
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn">
                        <FaEdit/>
                    </span>
                    <span className="delete-icon-btn">
                        <FaTrash/>
                        </span>
                    </div>
                </td>
      </tr>
    </>
  );
}

export default StoreAdminItem;
