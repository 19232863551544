import { useState, memo, useRef } from "react";
import {
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import {
  createUpdateAction,
  updateAllStorePriceAction,
  updateSingleStorePriceAction,
} from "../../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../../network/service/ImageUploadService";
import { Button } from "react-bootstrap";

const StoreProductListItem = (props) => {
  const [product] = useState(props.product);
  const [itemStatus, setitemStatus] = useState(product.item.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState();

  const onUpdateClick = async () => {
    let itemObj = {
      productId: product.item.id,
      storeId: props.storeId,
    };
    await dispatch(updateSingleStorePriceAction(itemObj, dispatch)).then(
      (response) => {
        console.log("createUpdateAction", response.result);
        if (response.result) {
          dispatch(
            updateMessage({
              display: true,
              message: "Prices updated.",
            })
          );
          navigate(0);
        }
      }
    );
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{product.item.id}</p>
      </td>
      <td className="align-middle">
        <div className="cat_mainwrap">
          {product.item.preview_image_path === "" ? null : (
            <div className="cat_btnwrap">
              <a href="">
                <FaRegEdit />
              </a>
              <a href="">
                <FaEye />
              </a>
            </div>
          )}
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
          {file ? (
            <>
              <img
                src={file}
                alt={product.item.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
              />
            </>
          ) : product.item.preview_image_path !== "" ? (
            <img
              src={product.item.preview_image_path}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      {/* to="/pinfo" state={{ product }} */}
      <td className="align-middle">
        <Link>
          <p>{product.item.name}</p>
        </Link>
      </td>
      <td className="align-middle">
        <p>{product.item.hsn}</p>
      </td>
      <td className="align-middle">
        <p>---</p>
      </td>{" "}
      <td className="align-middle">
        <p>----</p>
      </td>
      <td className="align-middle">
        <p>----</p>
      </td>
      <td className="align-middle">
        <p>----</p>
      </td>
      <td colSpan={2}>
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch  col-lg-12 d-flex justify-content-center">
          <Button onClick={() => onUpdateClick()}> Assign to Store</Button>
        </div>
      </td>
    </tr>
  );
};

export default memo(StoreProductListItem);
