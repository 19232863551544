import React, { useEffect, useState } from "react";
import { UseSelector, useDispatch } from "react-redux";
import { updateFormInfo } from "../redux/slice/formUpdateSlice";
import { productImageRequest } from "../network/store/action/ImageUploadResponseAction";
import EasyCrop from "../components/easy-crop";
import { imageListAction } from "../network/store/action/ProductResponseAction";

function ImageUpload(props) {
  const product = props.product;
  const dispatch = useDispatch();
  const [imageUpload, setImageUpload] = useState("");
  const [showImageCroper, setShowImageCroper] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [previewImages, setPreviewImages] = useState(
    product.images ? product.images : []
  );

  useEffect(() => {
    getProductImages();
  }, []);

  const getProductImages = async () => {
    await dispatch(
      imageListAction(
        {
          id: product.product.item.id,
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setPreviewImages(reponse.result);
      } else {
        setPreviewImages([]);
      }
    });
  };

  const onCancel = () => {
    if (props.onCancelClick) {
      props.onCancelClick(imageUpload !== "" ? true : false);
    }
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.product.item.id,
      fileData: form_data,
    };
    await dispatch(productImageRequest(requestBody)).then((response) => {
      console.log(response);
      console.log(response);
      if (response.status == true) {
        setPreviewImages([...previewImages, response.result]);
      } else {
      }
    });
  };

  const getCroppedImage = async (file) => {
    uploadImage(file);
    console.log(file);
    setImageUpload(file);
    let previewUrl = file ? URL.createObjectURL(file) : "";
    setImageUrl(previewUrl);
    setShowImageCroper(false);
  };

  return (
    <>
      {showImageCroper && imageUpload !== "" && (
        <div
          className="cropper"
          style={{
            width: "60%",
            height: "80%",
            background: "#fff",
            position: "absolute",
            left: "30%",
            zIndex: "9999",
            marginTop: "65px",
          }}
        >
          <EasyCrop
            image={URL.createObjectURL(imageUpload)}
            onCrop={(file) => {
              getCroppedImage(file);
            }}
            onCropClose={() => {
              setShowImageCroper(false);
              setImageUpload("");
            }}
          />
        </div>
      )}

      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-item-center justify-content-center">
          <div
            className="col-md-6"
            style={{
              marginLeft: "-200px",
              backgroundColor: "white",
              marginTop: "100px",
              padding: "20px",
              marginBottom: "100px",
            }}
          >
            <div className="container">
              <div className="row">
                {previewImages &&
                  previewImages.length !== 0 &&
                  previewImages.map((imageItem) => {
                    return (
                      <>
                        <div className="col-3">
                          <img
                            src={imageItem.image_path}
                            alt="..."
                            class="img-thumbnail"
                          />
                        </div>
                      </>
                    );
                  })}

                <div className="col-3">
                  <input
                    type="file"
                    onChange={(event) => {
                      uploadImage(event.target.files[0]);
                      //setImageUpload(event.target.files[0]);
                      //   // let previewUrl = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "";
                      //   // setImageUrl(previewUrl);
                      //   setIsImageChanged(true);
                      //setShowImageCroper(true);
                    }}
                  />
                </div>
                <div className="col-12">
                  <>
                    {/* <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-primary"
                          onClick={() => onSubmtClick()}
                        >
                          
                        </button> */}
                    <button
                      className="btn btn-white-bg"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      Cancel
                    </button>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageUpload;

//   const product = props.product;
//   console.log(product.product.image)
//   const dispatch = useDispatch()
//   const [imageUpload, setImageUpload] = useState("");
//   const [showImageCroper, setShowImageCroper] = useState(false);
//   const [imageUrl, setImageUrl] = useState("");
//   const [previewImages, setPreviewImages] = useState(product.product.images ? product.product.images : [])

//   const onCancel = () => {
//    if(props.onCancelClick){
//     props.onCancelClick(imageUpload !== "" ? true : false)
//    }
//     dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
//   }

//   const uploadImage = async (file) => {
//     const form_data = new FormData();
//     form_data.append("file", file);
//     let requestBody = {
//       id: product.product.item.id,
//       fileData: form_data
//     }
//     await dispatch(productImageRequest(requestBody)).then((response) => {
//       console.log(response);
//       console.log(response);
//       if (response.status == true) {
//         setPreviewImages([...previewImages, response.result])
//       } else {
//       }
//     });
//   }

//   const getCroppedImage = async (file) => {
//     uploadImage(file);
//     console.log(file)
//     setImageUpload(file);
//     let previewUrl = file ? URL.createObjectURL(file) : "";
//     setImageUrl(previewUrl);
//     setShowImageCroper(false);
//   };

//   return (
//     <>
//       {showImageCroper && imageUpload !== "" && (
//         <div
//           className="cropper"
//           style={{
//             width: "60%",
//             height: "80%",
//             background: "#fff",
//             position: "absolute",
//             left: "30%",
//             zIndex: "9999",
//             marginTop: "65px"
//           }}
//         >
//           <EasyCrop
//             image={URL.createObjectURL(imageUpload)}
//             onCrop={(file) => {
//               getCroppedImage(file);
//             }}
//             onCropClose={() => {
//               setShowImageCroper(false);
//               setImageUpload("");
//             }}
//           />
//         </div>
//       )}

//       <div
//         style={{
//           position: "fixed",
//           backgroundColor: "rgba(0,0,0,0.5)",
//           width: "100%",
//           height: "100%",
//           zIndex: "999",
//           overflow: "scroll",
//         }}
//       >
//         <div className="d-flex align-item-center justify-content-center">
//           <div className="col-md-6" style={{ marginLeft: "-200px", backgroundColor: "white", marginTop: "100px", padding: "20px", marginBottom: "100px" }}>
//             <div className="container" >
//               <div className="row">
//                 {previewImages && previewImages.length !== 0 && previewImages.map((imageItem) => {
//                   return (
//                     <>
//                       <div className="col-3">
//                         <img src={imageItem.image_path} alt="..." class="img-thumbnail" />
//                       </div>
//                     </>
//                   )
//                 })}

//                 <div className="col-3">
//                   <input
//                     type="file"
//                     onChange={(event) => {
//                       // uploadImage(event.target.files[0]);
//                       setImageUpload(event.target.files[0]);
//                       //   // let previewUrl = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "";
//                       //   // setImageUrl(previewUrl);
//                       //   setIsImageChanged(true);
//                       setShowImageCroper(true);
//                     }} />
//                 </div>
//                 <div className="col-12">
//                   <>
//                     {/* <button
//                           style={{ display: "flex", justifyContent: "center" }}
//                           type="button"
//                           className="btn btn-primary"
//                           onClick={() => onSubmtClick()}
//                         >

//                         </button> */}
//                     <button
//                       className="btn btn-white-bg"
//                       onClick={() => {
//                         onCancel();
//                       }}
//                     >
//                       Cancel
//                     </button>
//                   </>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ImageUpload;
