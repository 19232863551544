import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";
import { isAfterDate } from "../../../utils/dateUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import {
  BRANCH_API_PATH,
  COUPONS_API_PATH,
} from "../../../network/config/apiPaths";
import { axiosApi } from "../../../network/service/config/AaxiosUtil";
import Select from "react-select";

const AddUpdateStoreCoupon = (props) => {
  const navigate = useNavigate();
  const [limitStatus, setLimitStatus] = useState("NO");
  const [uniqueUser, setUniqueUser] = useState("NO");
  const [duration, setDuration] = useState("NONE");
  const [selectedOption, setSelectedOption] = useState(null);
  const location = useLocation();
  const itemInfo = location.state != null ? location.state.product : undefined;

  const [formData, setFormData] = useState({
    name: "",
    storeId: "", //only for Store
    code: "",
    offerPercentage: 1,
    description: "",
    uniqueUser: "NO", // YES or NO
    type: "ALL", // ALL / LOCATION / STORE
    limitApply: "NO", // YES or NO
    limitCount: 50,
    maxLimit: "", // offer max upto optional.
    startDate: "",
    endDate: "",
    daysLimit: "", // YES or NO, if days limit yes-> start and end date consider
    minOrderValue: 500,
    claims: "1",
    terms: "",
    duration: "NONE",
  });
  const [storeResult, setStoreResult] = useState([]);

  useEffect(() => {
    if (itemInfo != undefined) {
      let updateValues = {
        name: itemInfo.name,
        storeId: itemInfo.store_id, //only for Store
        code: itemInfo.code,
        offerPercentage: itemInfo.offer_percentage,
        description: itemInfo.description,
        uniqueUser: itemInfo.unique_user, // YES or NO
        type: itemInfo.type, // ALL / LOCATION / STORE
        limitApply: itemInfo.limit_apply, // YES or NO
        limitCount: itemInfo.limit_count,
        maxLimit: itemInfo.max_limit, // offer max upto optional.
        startDate: itemInfo.start_date,
        endDate: itemInfo.end_date,
        daysLimit: itemInfo.days_limit, // YES or NO, if days limit yes-> start and end date consider
        minOrderValue: itemInfo.min_order_value,
        claims: itemInfo.number_of_claims,
        terms: "",
        duration: itemInfo.days_limit,
        id: itemInfo.id,
      };
      setFormData(updateValues);
      setLimitStatus(itemInfo.limit_apply);
      setUniqueUser(itemInfo.unique_user);
      setDuration(itemInfo.days_limit);
    }
    StoreList();
  }, []);

  useEffect(() => {
    datesValidation();
  }, [formData.startDate, formData.endDate]);

  async function StoreList() {
    const data = await axiosApi.get(
      API_CONFIG.API_HOST + BRANCH_API_PATH.LIST_PATH,
      {
        headers: API_HEADERS,
      }
    );
    setStoreResult(data.data.result);

    if (itemInfo != undefined) {
      const defItem = data.data.result.find((element) => {
        return element.id === itemInfo.store_id;
      });

      setSelectedOption(defItem);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const datesValidation = () => {
    let to = formData.endDate;

    // if (new Date(formData.startDate) > new Date(to)) {
    //   alert("Select Valid Dates");
    // }

    if (
      duration === "DURATION" &&
      formData.startDate.length > 0 &&
      formData.endDate.length > 0 &&
      !isAfterDate(formData.startDate, to)
    ) {
      alert("Select Valid Dates");
    }
  };

  const onUniqueUserChange = async () => {
    let status = "YES";
    if (uniqueUser === "NO") {
      status = "YES";
    } else {
      status = "NO";
    }
    setUniqueUser(status);
    let requestBody = {
      status: status,
    };
  };

  const onLimitChange = async () => {
    let status = "YES";
    if (limitStatus === "NO") {
      status = "YES";
    } else {
      status = "NO";
    }
    setLimitStatus(status);
    let requestBody = {
      status: status,
    };
  };

  const onDurationChange = async () => {
    let status = "NONE";
    if (duration === "NONE") {
      status = "DURATION";
    } else {
      status = "NONE";
    }
    setDuration(status);
    let requestBody = {
      status: status,
    };
  };

  const validateForm = async () => {
    if (
      duration === "DURATION" &&
      !isAfterDate(formData.startDate, formData.endDate)
    ) {
      alert("Select valid Start adnd End Dates");
      return;
    }

    if (formData.code.length <= 0 || formData.name.length <= 0) {
      alert("Enter Valid Coupon Code / Name");
      return;
    }

    if (selectedOption === null) {
      alert("Select Branch");
      return;
    }

    const data = await fetch(
      API_CONFIG.API_HOST + COUPONS_API_PATH.CREATE_COUPON,
      {
        method: "POST",
        headers: API_HEADERS,
        body: JSON.stringify({
          name: formData.name,
          storeId: selectedOption.id, //only for Store
          code: formData.code,
          offerPercentage: formData.offerPercentage,
          description: formData.description,
          uniqueUser: uniqueUser, // YES or NO
          type: "STORE", // ALL / LOCATION / STORE
          limitApply: limitStatus, // YES or NO
          limitCount: formData.limitCount,
          maxLimit: formData.maxLimit, // offer max upto optional.
          startDate: formData.startDate,
          endDate: formData.endDate,
          daysLimit: duration, // NONE or DURATION, if days limit yes-> start and end date consider
          minOrderValue: formData.minOrderValue,
          claims: formData.claims,
          id: formData.id,
          // terms: formData.terms,
        }),
      }
    );

    if (data.status === 200) {
      navigate(-1);
    }
  };

  return (
    <>
      <Container fluid className="dashboard" style={{ marginTop: "80px" }}>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>Create / Update Coupon</CardTitle>
            {/* <Form > */}
            <div
              style={{
                marginTop: 20,
              }}
            >
              <Select
                options={storeResult}
                value={selectedOption !== null ? selectedOption : ""}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                onChange={(sOption) => {
                  console.log("selectedOptions", sOption);
                  setSelectedOption(sOption);
                }}
                // isMulti
              />
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="name">Promo Name</Label>
                    <Input
                      type="text"
                      name="name"
                      value={formData.name}
                      id="name"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="code">Promo Code</Label>
                    <Input
                      type="text"
                      name="code"
                      value={formData.code}
                      id="code"
                      onChange={handleChange}
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="offerPercentage">Offer Percentage</Label>
                    <Input
                      type="number"
                      name="offerPercentage"
                      id="offerPercentage"
                      placeholder=""
                      onChange={handleChange}
                      value={formData.offerPercentage}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="maxLimit">Max. Discount Amount</Label>
                    <Input
                      type="number"
                      name="maxLimit"
                      value={formData.maxLimit}
                      id="maxLimit"
                      onChange={handleChange}
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="minOrderValue">Min. Purchase Value</Label>
                    <Input
                      type="number"
                      name="minOrderValue"
                      id="minOrderValue"
                      value={formData.minOrderValue}
                      placeholder=""
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            {/* className="align-items-center" */}
            <div
              style={{
                marginTop: 20,
              }}
            >
              <Row>
                <Col md={3}>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={uniqueUser === "YES" ? true : false}
                      onChange={onUniqueUserChange}
                    />
                    <Label check>Unique User</Label>
                  </FormGroup>
                  {uniqueUser === "YES" && (
                    <FormGroup>
                      <Label for="claims">Number of Claims (Min 1.)</Label>
                      <Input
                        type="number"
                        name="claims"
                        id="claims"
                        value={formData.claims}
                        placeholder=""
                        onChange={handleChange}
                      />
                    </FormGroup>
                  )}
                </Col>

                <Col md={3} className="col-centered">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={limitStatus === "YES" ? true : false}
                      onChange={onLimitChange}
                    />
                    <Label check>Limited Users</Label>
                  </FormGroup>
                  {limitStatus === "YES" && (
                    <FormGroup>
                      <Label for="limitCount">Number of Users can use</Label>
                      <Input
                        type="number"
                        name="limitCount"
                        id="limitCount"
                        value={formData.limitCount}
                        placeholder=""
                        onChange={handleChange}
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col md={6} className="col-centered">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={duration === "DURATION" ? true : false}
                      onChange={onDurationChange}
                    />
                    <Label check>Limited Duration </Label>
                  </FormGroup>
                  {duration === "DURATION" && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="startDate">Start Date</Label>
                          <Input
                            type="date"
                            name="startDate"
                            id="startDate"
                            placeholder=""
                            value={formData.startDate}
                            onChange={handleChange}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="endDate">End Date</Label>
                          <Input
                            type="date"
                            name="endDate"
                            id="endDate"
                            placeholder=""
                            value={formData.endDate}
                            onChange={handleChange}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder=""
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            {/* <FormGroup check>
                <Input type="checkbox" name="check" id="exampleCheck" />
                <Label for="exampleCheck" check>
                  Check me out
                </Label>
              </FormGroup> */}

            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                color="primary"
                className="mt-2 btn"
                onClick={validateForm}
              >
                SUBMIT
              </Button>

              <button
                className="btn btn-white-bg"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </div>
            {/* </Form> */}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AddUpdateStoreCoupon;
