import {
  listRequest,
  priceCreateUpdate,
  productStatusUpdate,
  storeProductStatusUpdate,
} from "../../service/ProductPriceService";

/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
// export const listAction = (data) => async (dispatch) => {
//   const response = await dispatch(listRequest(data));
//   console.log(response);
//   if (
//     (response &&
//       Object.keys(response).length &&
//       response?.data?.status == 201) ||
//     200
//   ) {
//     console.log(response);
//     return Promise.resolve(response.data);
//   } else {
//     return Promise.resolve(null);
//   }
// };

export const priceCreateUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(priceCreateUpdate(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const productStatusUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(productStatusUpdate(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const spStatusUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(storeProductStatusUpdate(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
