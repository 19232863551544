import { useDispatch, useSelector } from "react-redux";
import { priceListAction } from "../../../network/store/action/ProductResponseAction";
import { useEffect, useState } from "react";
import {
  displayForm,
  formUpdateStatus,
} from "../../../redux/slice/formUpdateSlice";
import PriceEntryForm from "../../ProductTwo/price-edit-form";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import AdminItemPriceItem from "./admin-product-price-item";

function AdminItemPrices(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showRequestForm = useSelector(formUpdateStatus);
  const [resultList, setResultList] = useState([]);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(priceListAction({ productId: props.id }, dispatch)).then(
      (reponse) => {
        if (reponse.result) {
          setResultList(reponse.result);
        }
      }
    );
  };

  return (
    <>
      <div className="d-flex align-item-center justify-content-center">
        <div className="col-md-12">
          <div
            className="container card-1"
            style={{ minHeight: "300px", width: "100%" }}
          >
            <div className="row mt-5 mb-5">
              <div className="col-md-12" style={{ padding: "20px" }}>
                <div className="row justify-content-around tble">
                  <div className="col-6">
                    <h5 className="dashboard-title">Product Prices</h5>
                  </div>
                  <div className="mr-auto col-6 text-end">
                    <button
                      type="button"
                      className="mr-auto btn btn-purple-bg"
                      onClick={() => {
                        navigate("/priceupdate", { state: { id: props.id } });
                      }}
                    >
                      Add Price
                    </button>
                  </div>
                </div>
              </div>
              {resultList && resultList.length > 0 ? (
                <table className="table table-striped table-bordered border-sm  mt-2">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>QTY UOM</th>
                      <th>Purchase/Mfg Cost</th>
                      <th colSpan={2}>B2C</th>
                      {/* <th colSpan={2}>B2B</th> */}
                      <th>Available Stock</th>
                      <th>Product Status</th>
                      <th>Stock Status</th>
                      <th>Actions</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Price</th>
                      <th>Offer</th>
                      {/* <th>Price</th>
                      <th>Offer</th> */}
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultList.map((item, i) => {
                      return (
                        <AdminItemPriceItem
                          item={item}
                          key={item.id}
                          productId={props.id}
                        />
                      );
                      //  (
                      //   <tr>
                      //     <td>{item.sku}</td>
                      //     <td>
                      //       {item.qty} {"-"}
                      //       {item.measure}
                      //     </td>
                      //     <td>₹{item.purchase_price}</td>
                      //     <td>₹{item.price}</td>
                      //     <td>
                      //       {item.offer}% - ₹{item.offer_price}
                      //     </td>
                      //     <td>₹{item.b2bprice}</td>
                      //     <td>
                      //       {item.b2boffer}% - ₹{item.b2boffer_price}
                      //     </td>
                      //     <td> {item.stock_quantity}</td>

                      //     <td className="align-middle">
                      //       {/* <p>{item.status}</p> */}
                      //       <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                      //         <input
                      //           class="form-check-input"
                      //           type="checkbox"
                      //           role="switch"

                      //           //  checked={itemStatus === "YES" ? true : false}
                      //         />
                      //       </div>
                      //     </td>

                      //     <td className="align-middle">
                      //       {/* <p>{item.status}</p> */}
                      //       <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                      //         <input
                      //           class="form-check-input"
                      //           type="checkbox"
                      //           role="switch"
                      //           // onChange={onStatusChange}
                      //           // checked={itemStatus === "YES" ? true : false}
                      //         />
                      //       </div>
                      //     </td>

                      //     <td className="align-middle">
                      //       <p className="col-lg-12 d-flex justify-content-center">
                      //         <span
                      //           className="edit-icon-btn"
                      //           onClick={() => {
                      //             //   editItem();
                      //           }}
                      //         >
                      //           <FaRegEdit />
                      //         </span>

                      //         <span className="delete-icon-btn">
                      //           <FaTrash />
                      //         </span>
                      //       </p>
                      //     </td>
                      //   </tr>
                      // );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminItemPrices;
