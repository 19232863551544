import { Tab, Tabs } from "react-bootstrap";
import AdminItemPrices from "../price/admin/admin-product-prices";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import StoreItemPrices from "../price/store/store-product-prices";

function ProductDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state != null ? location.state.product : undefined;

  useEffect(() => {
    console.log(location.state);
  }, []);

  return (
    <>
      {product !== undefined && (
        <main className="container-fluid">
          <div className="row m-3">
            <div className="row justify-content-around tble">
              <div className="col-6">
                <h3 className="dashboard-title">{product.item.name}</h3>
                <label>HSN: {product.item.hsn}</label> <br />
                <label>{product.item.description}</label>
              </div>
              <div className="mr-auto col-6 text-end">
                <button
                  type="button"
                  className="mr-auto btn btn-danger"
                  onClick={() => navigate(-1)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="row m-3 mt-0">
            <div className="col-12 mt-4">
              <Tabs
                defaultActiveKey="prices"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="prices" title="Admin Prices">
                  <AdminItemPrices id={product.item.id} />
                </Tab>
                <Tab eventKey="storeprices" title="Store Prices">
                  <StoreItemPrices id={product.item.id} />
                </Tab>

                <Tab eventKey="sales" title="Sales">
                  Sales
                </Tab>
              </Tabs>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default ProductDetails;
