import { useState } from "react";
import { FaEdit, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function AttendItem(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const editItem = () => {
    navigate("/b2ba", { state: { item: item } });
  };
  return (
    <>
      <tr>
        <td> {item.id} </td>
        <td className="align-middle">{item.name}</td>
        <td className="align-middle">{item.branchName}</td>

        <td>{item.time_in}</td>
        <td> {item.time_out} </td>
        <td> {item.comments} </td>
      </tr>
    </>
  );
}

export default AttendItem;
