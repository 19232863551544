import React, { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { USER_API_PATH } from "../../../network/config/apiPaths";

import { axiosApi } from "../../../network/service/config/AaxiosUtil";
import AutoComplete from "react-google-autocomplete";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { listAction as storeListAction } from "../../../network/store/action/BranchResponseAction";
import { useDispatch } from "react-redux";

function AddDEUser() {
  // DE - delivery executive.
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const itemInfo = location.state != null ? location.state.item : undefined;

  const [branches, setBranchList] = useState([]);

  const [inputList, setInputList] = useState({
    role_id: "7",
    business_name: "",
    userName: "",
    phoneNumber: "",
    email: "",
    orgId: "",
  });

  const handleChanges = (e) => {
    if (e.target.name === "orgId") {
      branches.findIndex((obj) => obj.id === e.target.value);
      setInputList({
        ...inputList,
        orgId: e.target.value,
      });
    } else if (e.target.name === "phoneNumber") {
      const selected = e.target.value.slice(0, 10);
      setInputList({
        ...inputList,
        [e.target.name]: selected,
      });
    } else {
      setInputList({ ...inputList, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    getStores();
    if (itemInfo != undefined) {
      let updateValues = {
        role_id: itemInfo.role_id,
        business_name: itemInfo.business_name,
        userName: itemInfo.name,
        phoneNumber: itemInfo.phone,
        email: itemInfo.email,
        orgId: itemInfo.orgId,
      };
      setInputList(updateValues);
    }
  }, []);

  const labelStyle = {
    fontWeight: "bold",
  };

  async function CreateUser() {
    let requestData = {
      name: inputList.userName,
      email: inputList.email,
      password: inputList.phoneNumber,
      role_id: inputList.role_id,
      status: "ACTIVE",
      phone: inputList.phoneNumber,
      organizationId: inputList.orgId,
      businessName: inputList.business_name,
    };

    if (itemInfo != undefined) {
      requestData.id = itemInfo.id;

      const data = await axiosApi.put(
        API_CONFIG.API_HOST + USER_API_PATH.UPDATE_USER,
        requestData,
        {
          headers: API_HEADERS,
        }
      );
      navigate(-1);
    } else {
      const data = await axiosApi
        .post(
          API_CONFIG.API_HOST + USER_API_PATH.REGISTRATION_PATH,
          requestData,
          {
            headers: API_HEADERS,
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            navigate(-1);
          } else {
            alert(response.result);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            alert(error.response.data.result);
          } else if (error.request) {
            //reuquest fail
            alert("Network fail");
          } else {
            //reuquest fail
            alert("Network fail");
          }
        });
    }
  }

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  return (
    <>
      <div className="container-fluid dashboard d-flex flex-row justify-content-center">
        <div className="row m-5 w-50 card-1" style={{ padding: "20px" }}>
          {itemInfo != undefined ? (
            <h5>Update Delivery Executive</h5>
          ) : (
            <h5>Add Delivery Executive</h5>
          )}
          <div className="col-12">
            <div className="form-group">
              <label>Branch</label>
              <select
                name="orgId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={inputList.orgId}
              >
                <option value={""}>{"--Select Branch--"}</option>
                {branches &&
                  branches.length > 0 &&
                  branches.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          {/* <div className="col-12">
            <input
              className="form-control mt-4"
              name="business_name"
              value={inputList.business_name}
              placeholder="Business Name"
              type="text"
              onChange={handleChanges}
            />
          </div> */}

          <div className="col-12">
            <input
              className="form-control mt-4"
              name="userName"
              value={inputList.userName}
              placeholder="Name"
              type="text"
              onChange={handleChanges}
            />
          </div>

          <div className="col-12">
            <input
              type="number"
              placeholder="Phone number"
              name="phoneNumber"
              value={inputList.phoneNumber}
              onChange={handleChanges}
              className="form-control mt-4"
            />
          </div>
          <div className="col-12">
            <input
              type="email"
              name="email"
              value={inputList.email}
              onChange={handleChanges}
              className="form-control mt-4"
              placeholder="Email Id"
            />
          </div>
          {/* <div className="col-12 text-center">
            <button className="btn btn-success w-25 mt-4" onClick={CreateUser}>
              Submit
            </button>
          </div> */}

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              style={{ display: "flex", justifyContent: "center" }}
              type="button"
              className="btn btn-purple-bg"
              onClick={() => CreateUser()}
            >
              Submit
            </button>
            <button
              className="btn btn-white-bg"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDEUser;
