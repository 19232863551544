import {
  createUpdateRequest,
  deleteBanner,
  listRequest,
  updateStoreBanners,
} from "../../service/BannerService";
/**
 *
 * @param {*} data  JSON DATA TO SEND TO SERVER
 * @returns API RESULT
 */
export const listAction = (data) => async (dispatch) => {
  const response = await dispatch(listRequest(data));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const createUpdateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(createUpdateRequest(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const updateStoreBannersAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(updateStoreBanners(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export const deleteBannersAction = (data) => async (dispatch) => {
  const response = await dispatch(deleteBanner(data));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
