import { useEffect, useRef, useState } from "react";
import { FaImage, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";

import noImage from "../../../assets/no_image.jpg";
import { updateStoreSectionAction } from "../../../network/store/action/SectionResponseAction";

const StoreCategoryItem = (props) => {
  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.storeSectionStatus);
  const [storeSectionId, setStoreSectionId] = useState(item.storeSectionId);
  const dispatch = useDispatch();

  const onStatusChange = async () => {
    let status = "YES";
    if (item.storeSectionStatus == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      sectionId: item.id,
      storeId: props.storeId,
      id: storeSectionId === "NO" ? "" : storeSectionId,
    };

    await dispatch(updateStoreSectionAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.storeSectionStatus = status));
        setitemStatus(status);
        setStoreSectionId(response.result.id);
      }
    });
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      <td className="align-middle">
        <div className="">
          {item.image_path !== "" ? (
            <img
              src={item.image_path}
              alt={item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      <td className="align-middle">
        <p>{item.name}</p>
      </td>

      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
    </tr>
  );
};

export default StoreCategoryItem;
