import React from "react";
import {
  deletePath,
  displayRemoveAlert,
  formUpdateData,
  itemType,
} from "../redux/slice/formUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_CONFIG, API_HEADERS } from "../network/config/ApiConfig";
import { axiosApi } from "../network/service/config/AaxiosUtil";

function DeleteAlert(props) {
  const dispatch = useDispatch();

  const formData = useSelector(formUpdateData);
  const optionType = useSelector(itemType);
  const deleteApiPath = useSelector(deletePath);
  const itemInfo = formData.item;

  const deleteItem = async () => {
    await axiosApi
      .delete(API_CONFIG.API_HOST + deleteApiPath + itemInfo.id, {
        headers: API_HEADERS,
      })
      .then((response) => {
        console.log(response);
        dispatch(
          displayRemoveAlert({
            displayRemove: false,
            formData: null,
            deleteApiPath: "",
          })
        );
        if (props.onSubmit) props.onSubmit();
      });
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-item-center justify-content-center">
          <div className="col-md-8">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-5 card-1 p-5">
                  <h4 className="text-center">Delete {optionType}</h4>
                  <p className="text-center">
                    Are you sure you want to delete?
                  </p>

                  <div className="col-md-12">
                    <div className="form-group">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-purple-bg"
                          onClick={deleteItem}
                        >
                          Ok
                        </button>
                        <button
                          className="btn btn-white-bg"
                          onClick={() =>
                            dispatch(
                              displayRemoveAlert({
                                displayRemove: false,
                                formData: null,
                                deleteApiPath: "",
                              })
                            )
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteAlert;
