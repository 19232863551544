import { useDispatch, useSelector } from "react-redux";
import {
  priceListAction,
  storePriceListAction,
} from "../../../network/store/action/ProductResponseAction";
import { useEffect, useState } from "react";
import {
  displayForm,
  formUpdateStatus,
} from "../../../redux/slice/formUpdateSlice";
import { useNavigate } from "react-router-dom";
import AdminItemPriceItem from "./store-product-price-item";
import { listAction } from "../../../network/store/action/BranchResponseAction";
import StoreItemPriceItem from "./store-product-price-item";

function StoreItemPrices(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showRequestForm = useSelector(formUpdateStatus);
  const [storeList, setStoreList] = useState([]);
  const [resultList, setResultList] = useState([]);

  const [storeId, setStoreId] = useState("0");

  useEffect(() => {
    loadStores();
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [storeId]);

  const loadStores = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setStoreList(reponse.result);
        //  setStoreId(reponse.result[0].id);
      }
    });
  };
  const loadData = async () => {
    await dispatch(
      storePriceListAction({ productId: props.id, storeId: storeId }, dispatch)
    ).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };

  const handleChanges = (e) => {
    setStoreId(e.target.value);
  };

  return (
    <>
      <div className="d-flex align-item-center justify-content-center">
        <div className="col-md-12">
          <div
            className="container card-1"
            style={{ minHeight: "300px", width: "100%" }}
          >
            <div className="row mt-5 mb-5">
              <div className="col-md-12" style={{ padding: "20px" }}>
                <div className="row justify-content-around tble">
                  <div className="col-6">
                    <h5 className="dashboard-title">Product Prices</h5>
                  </div>
                  <div className="mr-auto col-2">
                    <div className="form-group">
                      <label>Stores</label>
                      <select
                        name="storeId"
                        className="form-select"
                        onChange={(e) => handleChanges(e)}
                        value={storeId}
                      >
                        <option value={""}>{"--Select Store--"}</option>
                        {storeList &&
                          storeList.length > 0 &&
                          storeList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {resultList && resultList.length > 0 ? (
                <table className="table table-striped table-bordered border-sm  mt-2">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>QTY UOM</th>
                      <th>Purchase/Mfg Cost</th>
                      <th colSpan={2}>B2C</th>
                      {/* <th colSpan={2}>B2B</th> */}
                      <th>Available Stock</th>
                      <th>Product Status</th>
                      <th>Stock Status</th>
                      <th>Actions</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Price</th>
                      <th>Offer</th>
                      {/* <th>Price</th>
                      <th>Offer</th> */}
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultList.map((item, i) => {
                      return (
                        <StoreItemPriceItem
                          item={item}
                          key={item.id}
                          productId={props.id}
                          storeId={storeId}
                        />
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreItemPrices;
