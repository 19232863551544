import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { listAction } from "../../network/store/action/ColorsResponseAction";
import AddUpdateForm from "./add-update-form";
import BrandItem from "./color-item";
import {
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import AppToast from "../../components/appToast";
import { updateMessage } from "../../redux/slice/toastSlice";
import ColorItem from "./color-item";

function Colors() {
  // to get api data

  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const showRequestForm = useSelector(formUpdateStatus);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };
  const onSubmit = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    setResultList([]);
    setSearchVal("");
    setFilterList([]);

    loadData();
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    console.log("search Value", e.target.value);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  return (
    <>
      {showRequestForm && (
        <AddUpdateForm
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Colors</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Color
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search Color</label>
              <input
                type="search"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter Color name"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div>
          <div className="col-md-12 card-1">
            <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  {/* <th scope="col">Image</th> */}
                  <th scope="col">Name</th>
                  <th scope="col">Color Code</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <ColorItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <ColorItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Colors;
