import { useLocation, useNavigate, useParams } from "react-router-dom";
import StoreOrders from "../../order/store/StoreOreders";
import { Tab, Tabs } from "react-bootstrap";
import StoreProducts from "../../ProductTwo/store/StoreProducts";
import StoreCategory from "../../category/store/store-category";

const StoreInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { storeId, storeName, storeAddress } = useParams();
  const storeId = location.state != null ? location.state.storeId : "";
  const storeName = location.state != null ? location.state.storeName : "";
  const storeAddress =
    location.state != null ? location.state.storeAddress : "";

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3 mt-0 d-flex flex-row">
          <div className="col-12 ">
            <h3 className="dashboard-title">{storeName}</h3>
            <h6>{storeAddress}</h6>
          </div>
          <div className="align-middle"></div>
        </div>
        <div className="row m-3">
          <div className="col-12 mt-4">
            <Tabs
              defaultActiveKey="orders"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="orders" title="Orders">
                <StoreOrders id={storeId} />
              </Tab>

              <Tab eventKey="products" title="Products">
                <StoreProducts id={storeId} />
              </Tab>

              <Tab eventKey="categories" title="Categories">
                <StoreCategory id={storeId} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoreInfo;
