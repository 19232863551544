import { PRODUCT_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.LIST_PATH}?limit=${data.limit}&offset=${data.offset}&storeId=${data.storeId}&sectionId=${data.sectionId}&categoryId=${data.categoryId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};

export const storeListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.STORE_PRODUCT_LIST_PATH}?limit=${data.limit}&offset=${data.offset}&storeId=${data.storeId}&sectionId=${data.sectionId}&categoryId=${data.categoryId}&userId=${data.userId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};

export const priceListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.PRODUCT_PRICE_LIST}?productId=${data.productId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};

export const storePriceListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.STORE_PRODUCT_PRICE_LIST}?productId=${data.productId}&storeId=${data.storeId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log(error);
    // TODO Handle Error
  }
};

export const updateAllStorePriceRequest = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.UPDATE_STORE_PRICES,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const updateSingleStorePriceRequest = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.UPDATE_SINGLE_STORE_PRICES,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const updateAllStoreSinglePriceRequest = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.UPDATE_ALL_STORE_SINGLE_PRICE,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const createUpdateRequest = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.PRODUCT_CREATE,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const imageListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.PRODUCT_IMAGE_LIST}?id=${data.id}`,
      null,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
  }
};

export const tagListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.TAG_LIST_PATH}?productId=${data.productId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    console.log("", error);
    // TODO Handle Error
  }
};

export const updateTags = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.PRODUCT_TAGS_UPDATE,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
